<template>
  <div v-if="cookies" class="cookies-container">
    <v-container>
      <v-row>
        <v-col cols="12" md="9" lg="9" xl="9">
          <v-row>
            <v-col cols="12" class="pb-0">
              Wir nutzen Cookies um Dir die bestmögliche Browsing-Erfahrung zu bieten.
              Die mit Hilfe von Cookies gesammelten Daten werden zur Optimierung unserer Webseite genutzt und um
              potenziellen Neukunden die für sie relevantesten Informationen anzuzeigen.
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-checkbox v-model="checkboxes.basic" disabled label="Notwendig"/>
              <v-checkbox v-model="checkboxes.statistics" label="Statistiken & Marketing"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="2" offset-md="1" lg="2" offset-lg="1" xl="2" offset-xl="1" style="display: flex;justify-content: center;align-items: center;">
          <v-row>
            <v-col cols="12">
              <v-btn color="secondary" @click="setAllCookies()">Alle akzeptieren</v-btn>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-btn color="secondary" text @click="setCookies()">OK</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'cookies',
  data() {
    return {
      cookies: false,
      checkboxes: {
        basic: true,
        statistics: false,
      },
    };
  },
  mounted() {
    if (navigator.cookieEnabled) {
      if (!this.$cookies.isKey('cookies')) {
        this.cookies = true;
      } else {
        this.addScript(Number(this.$cookies.get('cookies')));
      }
    } else {
      if(localStorage.getItem('cookies') === null) {
        this.cookies = true;
      } else {
        this.addScript(Number(localStorage.getItem('cookies')));
      }
    }
  },
  methods: {
    getSelectedOptions() {
      if (this.checkboxes.statistics) {
        return JSON.stringify(2);
      }
      return JSON.stringify(1);
    },
    setAllCookies() {
      this.checkboxes.statistics = true;
      this.setCookies();
    },
    setCookies() {
      this.$cookies.set('cookies', this.getSelectedOptions());
      localStorage.setItem('cookies', this.getSelectedOptions());
      this.addScript(Number(this.getSelectedOptions()));
      this.cookies = false;
    },
    addScript(option) {
      if (option === 2) {
        const script = document.createElement('script');
        script.type = "text/javascript";
        const code = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PVGV6ZP')";
        try {
          script.appendChild(document.createTextNode(code));
        } catch (e) {
          script.text = code;
        }
        document.body.appendChild(script);
        const noScript = document.createElement('noscript');
        noScript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PVGV6ZP" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
        document.body.appendChild(noScript);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .cookies-container {
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #ccc;
    box-shadow: 0 2px 10px #888888;
    z-index: 1000;
    background-color: white;
    text-align: left;
    ::v-deep .v-btn {
      width: 100%;
    }
    ::v-deep .v-input {
      display: inline-block;
      margin-right: 15px;
      .v-messages {
        display: none;
      }
    }
  }
</style>
