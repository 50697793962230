import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from 'vuetify/es5/locale/en';
import de from 'vuetify/es5/locale/de';

const vuetifyOptions = {
  options: {
    customProperties: true,
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#5C78FF',
        secondary: '#6376FE',
        accent: '#207663',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        white: '#ffffff',
        black: '#000000',
      },
      dark: {
        primary: '#5C78FF',
        secondary: '#6376FE',
        accent: '#207663',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        white: '#ffffff',
        black: '#ffffff',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  lang: {
    locales: { en, de },
    current: 'en',
  },
};

Vue.use(Vuetify);

export default new Vuetify(vuetifyOptions);
