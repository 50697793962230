import '@fontsource/roboto';
import Vue from 'vue';
import VueResource from 'vue-resource';
import VueCookies from 'vue-cookies';
import i18next from 'i18next';
import i18nextHttp from 'i18next-http-backend';
import VueI18Next from '@panter/vue-i18next';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueI18Next);
Vue.use(VueResource);
Vue.use(VueCookies);

Vue.$cookies.config('30d');

Vue.router = router;
Vue.http.options.root = 'https://yua683n1xa.execute-api.eu-central-1.amazonaws.com/prod/';

Vue.prototype.globalEvents = new Vue();

router.afterEach(() => {
  Vue.prototype.globalEvents = new Vue();
  Vue.nextTick(() => {
    window.scrollTo(0, 0);
  });
});

i18next
    .use(i18nextHttp)
    .init({
      fallbackLng: 'DE',
      lng: 'DE',
      backend: {
        loadPath: '/locales/{{lng}}.json',
      },
    });

const i18n = new VueI18Next(i18next);

/* ********************************************
 *    LAZY LOADING DIRECTIVE
 * ******************************************** */
const config = {
  rootMargin: '150px 150px 150px 150px',
  threshold: 0.01,
};
const lazyLoadImage = (el, src) => {
  /* eslint-disable no-new */
  /* eslint-disable no-param-reassign */
  if ('IntersectionObserver' in window) {
    const oberver = new IntersectionObserver((entries) => {
      if (entries.length > 1 && entries[1].intersectionRatio > 0) {
        const img = new Image();
        img.onload = () => {
          //entries[0].target.style.cssText = null;
          el.src = src;
        };
        img.src = src;
        oberver.disconnect();
      } else if (entries[0].intersectionRatio > 0) {
        const img = new Image();
        img.onload = () => {
          //entries[0].target.style.cssText = null;
          el.src = src;
        };
        img.src = src;
        oberver.disconnect();
      }
    }, config);
    oberver.observe(el);
  } else {
    const img = new Image();
    img.onload = () => {
      el.src = src;
    };
    img.src = src;
  }
};
Vue.directive('lazyimg', {
  bind(el, binding) {
    lazyLoadImage(el, binding.value);
  },
  update(el, binding) {
    if (binding.value !== binding.oldValue) {
      lazyLoadImage(el, binding.value);
    }
  },
});

new Vue({
  i18n,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
