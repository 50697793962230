<template>
  <v-app>
    <v-toolbar flat :class="[{'scrolled' : navigation.scrolled}, {'darkened': !isLandingPage()}]">
      <v-toolbar-title>
        <img @click="goToLanding()" alt="SuperRent" title="Super Rent"
             src="../public/images/Super_Rent_Logo_White.svg" v-if="!navigation.scrolled && isLandingPage()">
        <img @click="goToLanding()" alt="SuperRent" title="Super Rent"
             src="../public/images/Super_Rent_Logo_Dark.svg" v-else>
      </v-toolbar-title>
      <v-spacer/>
      <v-btn outlined v-if="$vuetify.breakpoint.mdAndUp && isLandingPage()" class="mr-0"
             @click.stop="closeMenu('#contact')">
        Kontakt
      </v-btn>
      <v-btn outlined v-if="$vuetify.breakpoint.mdAndUp && !isLandingPage()" class="mr-0"
             @click.stop="goToLanding()">
        Startseite
      </v-btn>
      <v-btn outlined class="mr-0"
             @click.stop="openPage('https://blog.hirschhuber.com/category/super-rent/')">
        Blog
      </v-btn>
      <v-toolbar-items>
        <div :class="['navigation-hamburger-menu', {'open':navigation.menu}]"
             @click="openNavigationMenu()" v-if="$vuetify.breakpoint.smAndDown">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </v-toolbar-items>
    </v-toolbar>
    <v-main>
      <router-view/>
    </v-main>

    <div class="home-footer">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="6" class="home-footer-large-logo">
            <v-img @click="openPage('https://supermobility.at/')" contain
                   src="../public/images/Super_Mobility_Logo_White.svg"/>
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="6">
            <v-row>
              <v-col cols="12" sm="4" class="home-footer-small-logo text-center">
                <v-img @click="openPage('https://supertracker.eu/')" contain
                       src="../public/images/Super_Tracker_Logo_White.svg"/>
              </v-col>
              <v-col cols="12" sm="4" class="home-footer-small-logo text-center">
                <v-img src="../public/images/Super_Rent_Logo_White.svg" contain/>
              </v-col>
              <v-col cols="12" sm="4" class="home-footer-small-logo text-center">
                <v-img src="../public/images/Super_Shop_Logo_White.svg" contain/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="navigation.menu" fullscreen hide-overlay origin="top right"
              content-class="dialog-navigation-content">
      <v-card>
        <v-toolbar flat color="white" class="dialog-navigation">
          <v-toolbar-title>
            <img @click="goToLanding()" alt="SuperRent" title="Super Rent"
                 src="../public/images/Super_Rent_Logo_Dark.svg">
          </v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <div :class="['navigation-hamburger-menu', {'open':navigation.menu}]" @click="closeNavigationMenu()">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-main>
            <v-row>
              <v-col cols="11" offset="1" sm="12" class="pt-1 pb-1" v-if="isLandingPage()">
                <a class="main-link" @click="closeMenu('#contact')">
                  Kontakt
                </a>
              </v-col>
              <v-col cols="11" offset="1" sm="12" class="pt-1 pb-1" v-if="!isLandingPage()">
                <a class="main-link" @click="goToLanding()">
                  Startseite
                </a>
              </v-col>
            </v-row>
          </v-main>
        </v-container>
      </v-card>
    </v-dialog>
    <v-fab-transition>
      <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right color="secondary" @click="toTop">
        <v-icon>{{icons.arrowUp}}</v-icon>
      </v-btn>
    </v-fab-transition>
    <cookies/>
  </v-app>
</template>

<script>
import { mdiMenu, mdiChevronUp, mdiClose } from '@mdi/js'

import Cookies from './components/Cookies';

export default {
  name: 'App',
  components: {
    Cookies,
  },
  data: () => ({
    icons: {
      menu: mdiMenu,
      arrowUp: mdiChevronUp,
      close: mdiClose,
    },
    navigation: {
      scrolled: false,
      menu: false,
      position: 0,
      scrollingDown: false,
    },
    fab: false,
  }),
  mounted() {
    window.addEventListener('scroll', () => {
      this.navigation.scrolled = window.scrollY > 50;
      this.navigation.scrollingDown = this.navigation.position < window.scrollY;
      this.navigation.position = window.scrollY;
    });
  },
  methods: {
    openPage(page) {
      window.open(page, '_blank');
    },
    closeNavigationMenu() {
      this.navigation.menu = false;
    },
    goToLanding() {
      this.closeNavigationMenu();
      if (this.$route.name !== 'Home') {
        this.$router.push({ name: 'Home' });
      } else {
        this.toTop();
      }
    },
    goTo(pathName) {
      this.$router.push({ name: pathName });
    },
    closeMenu(selector) {
      if (selector !== undefined && selector !== null) {
        this.globalEvents.$emit('goToElement', selector);
      }
      this.closeNavigationMenu();
    },
    openNavigationMenu() {
      this.navigation.menu = true;
    },
    onScroll() {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || document.documentElement.offsetTop || 0;
      this.fab = top > 50;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    isLandingPage() {
      return this.$route.name === 'Home';
    }
  }
};
</script>

<style lang="scss">
  @import "assets/scss/variables.scss";
  @import "assets/scss/layout.scss";
  .theme--light {
    &.v-application {
      font-family: $default-font !important;
    }
  }
  .v-application {
    font-family: $default-font !important;
  }
  .v-toolbar {
    height: 87px !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 100 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    &.v-sheet {
      background-color: transparent !important;
    }
    @media (min-width: 960px) {
      .v-toolbar__content {
        max-width: 900px !important;
      }
    }
    @media (min-width: 1264px) {
      .v-toolbar__content {
        max-width: 1185px !important;
      }
    }
    @media (min-width: 1904px) {
      .v-toolbar__content {
        max-width: 1785px !important;
      }
    }
    .v-toolbar__title {
      img {
        cursor: pointer;
        height: 45px;
      }
      @include MQ(XS) {
        margin-top: -15px;
      }
    }
    .v-toolbar__content {
      height: 87px !important;
      width: 100%;
      .v-btn {
        margin-top: 5px;
        border: none;
        text-transform: none;
        width: auto;
        margin-left: 25px;
        margin-right: 25px;
        @include MQ(M) {
          margin-left: 15px;
          margin-right: 15px;
        }
        .v-btn__content {
          color: white;
          font-weight: 300;
        }
      }
    }
    .navigation-hamburger-menu {
      width: 38px;
      height: 25px;
      position: relative;
      margin: 20px auto;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: white;
        border-radius: 3px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }
      span:nth-child(1) {
        top: 0;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      span:nth-child(2) {
        top: 10px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      span:nth-child(3) {
        top: 20px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      &.open span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0;
        left: 0;
      }
      &.open span:nth-child(2) {
        width: 0;
        opacity: 0;
      }
      &.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 26px;
        left: 0;
      }
      &.white-navigation span {
        background: white;
      }
    }
    &.scrolled {
      // background-color: $background-color-dark !important;
      box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),
      0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12) !important;
      height: 60px !important;
      .v-toolbar__title {
        img {
          margin-top: 10px;
        }
        @include MQ(XS) {
          margin-top: 0;
        }
      }
      &.v-sheet {
        background-color: white !important;
      }
      .v-toolbar__content {
        height: 60px !important;
        .v-btn {
          .v-btn__content {
            color: black;
          }
        }
        .v-toolbar__title {
          img {
            height: 40px;
          }
        }
      }
      .navigation-hamburger-menu {
        span {
          background: black;
        }
      }
    }
    &.darkened {
      .v-toolbar__content {
        .v-btn {
          .v-btn__content {
            color: black;
          }
        }
      }
      .navigation-hamburger-menu {
        span {
          background: black;
        }
      }
    }
  }
  .dialog-navigation-content {
    .v-card {
      border-radius: 0 !important;
    }
    .dialog-navigation {
      .v-toolbar__content {
        .v-toolbar__title {
          img {
            margin-top: 15px !important;
            position: relative !important;
            top: unset !important;
            left: unset !important;
            @include MQ(XS) {
              margin-top: 8px !important;
            }
          }
        }
        .v-toolbar__items {
          padding-top: 0 !important;
          padding-right: 0 !important;
          .navigation-hamburger-menu {
            span {
              background: black;
            }
          }
        }
      }
    }
    .container {
      padding-top: 150px;
      text-align: left;
      overflow: hidden;
      a {
        color: black;
        font-weight: 300;
        text-shadow: 0 3px 6px #00000029;
        letter-spacing: 0;
        &.main-link {
          font-size: 35px;
          @include MQ(XS) {
            font-size: 32px;
          }
        }
        &.sub-link {
          font-size: 25px;
          line-height: 30px;
          @include MQ(XS) {
            font-size: 20px;
          }
        }
      }
      .navigation-image-container {
        height: 200px;
        border-radius: 8px;
        color: #444444;
        @include MQ(XS) {
          height: 120px;
        }
        &.white-navigation-text {
          color: white;
        }
        .inner-navigation-image-container {
          border-radius: 8px;
          height: 100%;
          position: relative;
          cursor: pointer;
          .navigation-image-background {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            position: absolute;
            z-index: 0;
          }
          .navigation-image-title {
            font-weight: 600;
            text-shadow: 0 3px 6px #00000029;
            letter-spacing: 0;
            font-size: 35px;
            line-height: 35px;
            position: relative;
            @include MQ(XS) {
              font-size: 20px;
              line-height: 20px;
            }
            @include MQ(S) {
              font-size: 30px;
              line-height: 30px;
            }
          }
          .navigation-image-learn-more {
            font-size: 16px;
            font-style: italic;
            font-weight: 600;
            position: absolute;
            bottom: 5px;
            @include MQ(XS) {
              bottom: 0;
            }
            svg {
              height: 12px;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  .home-footer {
    margin-top: 50px;
    padding: 5px 15px 0;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    background: transparent linear-gradient(270deg, #6691D5 0, #965177 100%) 0 0 no-repeat padding-box;
    @include MQ(XS) {
      display: block;
      justify-content: unset;
      padding: 10px 25px;
    }
    @include MQ(S) {
      margin-top: 80px;
    }
    @include MQ(M) {
      margin-top: 100px;
    }
    @include MQ(L) {
      margin-top: 150px;
    }
    .home-footer-large-logo {
      text-align: left;
      @include MQ(XL) {
        .v-image {
          width: 200px;
        }
      }
      @include MQ(L) {
        .v-image {
          width: 200px;
        }
      }
      @include MQ(M) {
        .v-image {
          width: 200px;
        }
      }
      @include MQ(S) {
        text-align: center;
      }
      @include MQ(XS) {
        text-align: center;
      }
      .v-image {
        display: inline-block;
        height: 30px;
        cursor: pointer;
      }
    }
    .home-footer-small-logo {
      text-align: right;
      @include MQ(XS) {
        text-align: center;
      }
      .v-image {
        display: inline-block;
        height: 23px;
        margin-top: 5px;
        cursor: pointer;
      }
    }
  }
</style>
